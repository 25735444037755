// react/gatsby imports
import React from "react";
// assets
import ogImage from "assets/images/seo/collage.jpg";
import ogImageVK from "assets/images/seo/vk/collage.jpg";
// data
import localData from "data/pages/collage";
// hooks
import useCollagePortfolio from "hooks/portfolio/useCollagePortfolio";
import useCollageQuery from "hooks/graphql/useCollageQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import CourseExplanations from "components/pages/course-page/explanations";
import CoursePriceRange from "components/pages/course-page/price-range";
import EducationQuestions from "components/pages/course-page/education-questions";
import MasterClassTemplateOffer from "components/pages/course-page/offer/master-class";
import Mailing from "components/common/mailing";
import MasterClassPortfolio from "components/pages/course-page/portfolio/MasterClassPortfolio";
import SchoolSystem from "components/pages/course-page/school-system";
import PaymentChoices from "components/pages/payment-choices";

const CollagePage = () => {
  const prismicData = useCollageQuery();
  const portfolio = useCollagePortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};
  return (
    <Layout logo="collage">
      <div className="section-top-block" />
      <SEO
        title="Графсистема Коллаж"
        description={
          seoData?.seodescription?.text || data.coursedescription?.text
        }
        keywords={["коллаж", "графсистема", "гранич", "granich"]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicSystemCollage)}
      />
      <MasterClassTemplateOffer courseName="Коллаж" data={data} />
      <CourseExplanations twoRows data={localData.CollageExplanations} />
      <SchoolSystem />
      <MasterClassPortfolio
        pinterestText={"Коллаж в Энциклопедии\nГрафдизайна Школы"}
        pinterestLink="https://www.pinterest.ru/vadim_granich/collage/"
        portfolio={portfolio}
        graphicSystemName="Коллаж"
        courseTitle={data.coursetitle?.text}
      />
      <div id="participation-section" />
      {data.coursestatus ? (
        <CoursePriceRange
          formId="ltForm6902564"
          priceRange={localData.priceRange}
          courseTitle={data.coursetitle?.text}
          formPostUrl="https://school.granich.design/pl/lite/block-public/process-html?id=1487444082"
        />
      ) : (
        <Mailing />
      )}
      <PaymentChoices courseType="Мастер-класс" />
      <EducationQuestions />
    </Layout>
  );
};

export default CollagePage;
