/* eslint-disable */
import { useStaticQuery, graphql } from "gatsby";

const useCollagePortfolio = () => {
  const query = useStaticQuery(graphql`
    query collagePortfolio {
      poster1: file(relativePath: { eq: "collage/portfolio/01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster2: file(relativePath: { eq: "collage/portfolio/02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster3: file(relativePath: { eq: "collage/portfolio/03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster4: file(relativePath: { eq: "collage/portfolio/04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster5: file(relativePath: { eq: "collage/portfolio/05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster6: file(relativePath: { eq: "collage/portfolio/06.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster7: file(relativePath: { eq: "collage/portfolio/07.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster8: file(relativePath: { eq: "collage/portfolio/08.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster9: file(relativePath: { eq: "collage/portfolio/09.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster10: file(relativePath: { eq: "collage/portfolio/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster11: file(relativePath: { eq: "collage/portfolio/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster12: file(relativePath: { eq: "collage/portfolio/12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const portfolio = Object.values(query);

  const data = [
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53572",
      src: portfolio[0].childImageSharp.fluid,
      author: "Александр Белов",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535722",
      src: portfolio[1].childImageSharp.fluid,
      author: "Анастасия Достовалова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53573",
      src: portfolio[2].childImageSharp.fluid,
      author: "Николай Федоров",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53574",
      src: portfolio[3].childImageSharp.fluid,
      author: "Дарья Бирюкова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53575",
      src: portfolio[4].childImageSharp.fluid,
      author: "Алиса Теряева",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53576",
      src: portfolio[5].childImageSharp.fluid,
      author: "Александр Селиванов",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53577",
      src: portfolio[6].childImageSharp.fluid,
      author: "Полина Шаповалова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53579",
      src: portfolio[7].childImageSharp.fluid,
      author: "Марьям Будагова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53510",
      src: portfolio[8].childImageSharp.fluid,
      author: "Ярослава Садомова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53511",
      src: portfolio[9].childImageSharp.fluid,
      author: "Наталья Грачёва",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53512",
      src: portfolio[10].childImageSharp.fluid,
      author: "Дмитрий Денисов",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53513",
      src: portfolio[11].childImageSharp.fluid,
      author: "Екатерина Шелепова",
    },
  ];

  return data;
};

export default useCollagePortfolio;
